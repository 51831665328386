@font-face {
    font-family: 'futura-medium-bt', sans-serif;
    src: url('../webfonts/futura-medium-bt.ttf');
}

.menu-link{
    color: white;
}

.menu-link:hover{
    color: #c9a755;
}

tbody > tr > td {
    color: #0F3F62;
    font-weight: 600;
    vertical-align: middle;
}

table.table th, table.table td{
    vertical-align: middle;
}

.border-primary-top{
    border-top: solid #be1622 3px;
}